import React, { useEffect, useState } from 'react';
import {Grid} from "@mui/material";
import MarketingCampaign from 'app/shared/widgets/MarketingCampaign/MarketingCampaign';
import ObjectCountRevenue from "../../../shared/metrics/ObjectCountCards/ObjectCountRevenue";
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import RedeemIcon from '@mui/icons-material/Redeem';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { gettotalproducts, getpendingorders, getactiveusers, getallusers, getinactiveusers, getreffrelbonus, lasttransactions } from 'backendServices/ApiCalls';
import { useContext } from 'react';
import { CustomProvider } from 'app/layouts/vertical-default/VerticalDefault';
import { Group, ShoppingCart, VerifiedUser } from '@mui/icons-material';

const Crypto = () => {
    const [totalProductsData,setTotalProductsData]=useState(0)
    const [totalPendingOrders,setTotalPendingOrders]=useState(0)
    const [activeuser,setActiveUser]=useState(0)
    const [inactiveuser,setInactiveUser]=useState(0)
    const [totaluser,setTotalUser]=useState(0)
    const [totalreffrelbonus,setTotalReffrelBonus]=useState(0)
    const [lasttransactionsdata,setLastTransactionsData]=useState([])
    
    const {loginUserData} = useContext(CustomProvider);
    let userData = loginUserData

    const TotalProducts =()=>{
        gettotalproducts((response) => {
            setTotalProductsData(response?.data?.data[0])
        }, (error) => {
            console.log(error?.response?.data);
        })
    }

    const TotalAvtiveusers =()=>{
        getactiveusers((response) => {
            setActiveUser(response?.data?.data[0])
        }, (error) => {
            console.log(error?.response?.data);
        })
    }

    const TotalInavtiveusers =()=>{
        getinactiveusers((response) => {
            setInactiveUser(response?.data?.data[0])
        }, (error) => {
            console.log(error?.response?.data);
        })
    }
    const TotalUsers =()=>{
        getallusers((response) => {
            setTotalUser(response?.data?.data[0])
        }, (error) => {
            console.log(error?.response?.data);
        })
    }

    const TotalPendingOrders =()=>{
        getpendingorders((response) => {
            setTotalPendingOrders(response?.data?.data[0])
        }, (error) => {
            console.log(error?.response?.data);
        })
    }
  
    const ReffrelBonus =()=>{
        getreffrelbonus((response) => {
            setTotalReffrelBonus(response?.data?.data[0].total_referral_bonus.toFixed(2))
        }, (error) => {
            console.log(error?.response?.data);
        })
    }
    

    const LastWeekTransactions =()=>{
        lasttransactions((response) => {
            setLastTransactionsData(response?.data?.data?.entries)
        }, (error) => {
            console.log(error?.response?.data);
        })
    }

    

    useEffect(()=>{
        TotalProducts();
        TotalPendingOrders();
        TotalAvtiveusers();
        TotalInavtiveusers();
        TotalUsers();
        ReffrelBonus();
        LastWeekTransactions()
    },[])





    if(userData.firstname === "")
    {

        return <div>loading</div>
    }

    return (
        <Grid container spacing={3.75}>
             <Grid item xs={12} sm={6} lg={4}>
                <ObjectCountRevenue value={totalPendingOrders?.pending_order_count} title='p_order' color="success.main"  icon={<RedeemIcon fontSize='large'/>}  vertical={true}/>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <ObjectCountRevenue value={totalProductsData?.product_count} title='totalproducts' color="warning.main"  icon={<ShoppingCart fontSize='large'/>}  vertical={true}/>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
                <ObjectCountRevenue value={totalreffrelbonus } title='referralbonus' color="primary.main" icon={<AccountBalanceWalletRoundedIcon fontSize='large'/>} vertical={true}/>
            </Grid>
    
            <Grid item xs={12} sm={6} lg={4}>
                <ObjectCountRevenue value={activeuser?.total_active_users} title='activeusers' color="success.main"  icon={<VerifiedUser fontSize='large'/>}  vertical={true}/>
            </Grid> 
            <Grid item xs={12} sm={6} lg={4}>
                <ObjectCountRevenue value={inactiveuser?.total_inactive_users || 0} title='inactiveusers' color="warning.main"  icon={<GroupAddIcon fontSize='large'/>}  vertical={true}/>
            </Grid> 
            <Grid item xs={12} sm={6} lg={4}>
                <ObjectCountRevenue value={totaluser?.total_users} title='tusers' color="info.main"  icon={<Group fontSize='large'/>}  vertical={true}/>
            </Grid> 
            <Grid item xs={12} lg={12}>
                <MarketingCampaign lasttransactionsdata={lasttransactionsdata}/>
            </Grid>
        </Grid>
    );
};

export default Crypto;
