import React, { useState } from 'react'
import AddShipping from './AddShippingCharges'
import ManageShipping from './ManageShipping'
import { postRequest } from 'backendServices/ApiCalls'
import { useEffect } from 'react'
import SweetAlert from 'app/pages/components/mui/Alerts/SweetAlert';

const ShippingCharges = () => {
    const [shippingList, setShippingList] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [alertData, setalertData] = React.useState({
      show: false,
      message: "",
      variant: ""
  })
    const fetchShippingList = () => {
        postRequest('getshippinglist','', (response) => {
          if(response?.data?.status === "success")
          {
            setShippingList(response?.data?.data);
          }
          setIsLoading(false);

        });
      };
    
    
      useEffect(() => {
        fetchShippingList();
      }, []);

  return (
    <>
          {
            alertData.show && (<SweetAlert alertData={alertData} setalertData={setalertData} />)
          }
        <AddShipping fetchShippingList={fetchShippingList} setalertData={setalertData} />
        <ManageShipping  shippingList={shippingList} setShippingList={setShippingList} setalertData={setalertData} isLoading={isLoading} />
    </>
  )
}

export default ShippingCharges